<template>
  <div class="home">
    <div style="position: fixed;bottom: 5px;color: rgba(255,255,255,0.6)">
      <a href="https://beian.miit.gov.cn/" target="_blank"
         style="font-size: 12px;color: rgba(255,255,255,0.6);margin-right: 15px">蜀ICP备2022025016号-1</a>
      禁止发布、传播任何违法、违规内容，使用本网站，视您接受并同意
      <a href="https://docs.qq.com/doc/p/8f74cd62707b68ef4043ad9a643f905c4b7906c7" target="_blank"
         style="font-size: 13px;font-weight: bold;color: #2977ff">
        《网站免责声明》
      </a>
    </div>
    <div class="home-container">
      <div class="home-menu">
        <div class="menu-header">
          <img class="user-avatar" :src="currentUser.avatar" @click="userProfileVisible=true" ref="user-avatar"/>
          <div class="user-profile" v-if="userProfileVisible" ref="user-profile">
            <div class="user-profile-main">
              <div class="user-profile-header">
                <img :src="currentUser.avatar"/>
                <div>{{ currentUser.name }}</div>
              </div>
              <div class="user-profile-info">
                <div class="user-profile-info-title">简介</div>
                <div class="user-profile-info-text"
                     v-if="currentUser.introduction===''||currentUser.introduction==null">未填写
                </div>
                <div class="user-profile-info-text" v-else>{{ currentUser.introduction }}</div>
              </div>
              <div class="user-profile-info">
                <div class="user-profile-info-title">邮箱</div>
                <div class="user-profile-info-text" v-if="currentUser.email===''||currentUser.email==null">未填写</div>
                <div class="user-profile-info-text" v-else>{{ currentUser.email }}</div>
              </div>

              <div class="user-profile-footer">
                <el-button type="primary" size="medium" plain
                           @click="information.visible=true;userProfileVisible=false;" ref="editInfoBtn">
                  编辑资料
                </el-button>
                <el-button type="danger" size="medium" plain @click="logout">退出登录</el-button>
              </div>

            </div>
          </div>
        </div>
        <div class="menu-box">
          <div class="menu-list">
            <router-link tag="div" class="menu-item" to="/conversations">
              <i class="iconfont icon-zaixiankefu"></i>
              <span v-if="unreadAmount" class="menu-unread">{{ unreadAmount }}</span>
            </router-link>
            <router-link tag="div" class="menu-item" to="/contacts">
              <i class="iconfont icon-haoyou"></i>
            </router-link>
            <router-link tag="div" class="menu-item" to="/chatgpt">
              <i class="hmyIconfont hmyIcon-ChatGPT"></i>
            </router-link>
            <router-link tag="div" class="menu-item menu-item-moments" to="/moments">
              <i class="hmyIconfont hmyIcon-pengyouquan" style="font-size: 30px"></i>
            </router-link>


          </div>

          <div class="exit">
<!--            <i class="iconfont icon-h" @click="logout"></i>-->

<!--            <router-link tag="div" class="menu-item" to="/more">-->
<!--              <i class="hmyIconfont hmyIcon-gengduo" style="font-size: 20px"></i>-->
<!--            </router-link>-->
          </div>
        </div>
      </div>
      <div class="home-main">
        <router-view/>

      </div>

      <div class="information-box" v-if="information.visible" ref="information-box">
        <div class="information-box-main">
          <div class="information-box-title">编辑资料</div>
          <el-form label-position="top" label-width="80px" :model="informationData">
            <div class="avatar-select">
              <div v-for="(avatar, index) in information.avatars"
                   :key="index"
                   :class="{ 'active': information.selectedAvatar === index, 'large': information.selectedAvatar === index, 'small': information.selectedAvatar !== index }"
                   @click="selectAvatar(index)">
                <img :src="avatar" alt="avatar">
              </div>
            </div>
            <el-form-item>
              请输入简介:
              <el-input v-model="informationData.introduction"></el-input>
            </el-form-item>
            <el-form-item>
              请输入邮箱:
              <el-input v-model="informationData.email"></el-input>
            </el-form-item>

            <div class="information-box-footer">
              <el-button type="danger" size="medium" @click="information.visible=false;" plain>取消</el-button>
              <el-button type="primary" size="medium" @click="saveInformation" plain>保存</el-button>
            </div>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>


export default {
  data() {
    return {
      currentUser: null,
      unreadAmount: null,
      userProfileVisible: false,
      information: {
        visible: false,
        avatars: [
          '/static/images/boy1.png',
          '/static/images/boy2.png',
          '/static/images/boy3.png',
          '/static/images/boy4.png',
          '/static/images/boy5.png',
          '/static/images/girl1.png',
          '/static/images/girl2.png',
          '/static/images/girl3.png',
          '/static/images/girl4.png',
          '/static/images/girl5.png',
        ],
        selectedAvatar: 0,
      },
      informationData: {
        avatar: '',
        introduction: '',
        email: ''
      }
    };
  },
  created() {
    this.currentUser = this.globalData.currentUser;

    this.initWebSocket();

    this.$store.state.initConversation = this.initConversation;

    this.$store.state.webSocketMethod.onConversationsUpdate = this.initConversation;
    this.initConversation();

    document.addEventListener('click', (event) => {
      if (this.userProfileVisible === true) {
        let userProfile = this.$refs["user-profile"];
        let userAvatar = this.$refs["user-avatar"];
        if (!userProfile.contains(event.target) && event.target !== userAvatar) {
          this.userProfileVisible = false;
        }
      }


    });

    this.informationData.id = this.currentUser.id;
    this.informationData.avatar = this.currentUser.avatar;
    this.informationData.introduction = this.currentUser.introduction;
    this.informationData.email = this.currentUser.email;
    this.information.selectedAvatar = this.information.avatars.indexOf(this.informationData.avatar);


  },
  methods: {
    // setUnreadNumber(content) {
    //   this.unreadAmount = content.unreadTotal;
    // },
    initConversation() {
      this.$http.get("/conversation/list/" + this.currentUser.id).then((res) => {
        if (res.data) {
          let content = res.data;
          this.unreadAmount = content.unreadTotal;
          try {
            this.$store.state.loadConversations(content);
          } catch (e) {
          }
        } else {
          console.log('获取最新会话列表失败');
        }
      })

    },
    logout() {
      this.$confirm('确定要退出登录吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {

        localStorage.removeItem("user_save");
        this.globalData.currentUser = null;
        this.$store.state.webSocket.close();
        this.$router.replace({path: './login'});
      })
    },

    initWebSocket() {
      if (typeof (WebSocket) == 'undefined') {
        console.log("你的浏览器不支持WebSocket");
      } else {
        this.$store.state.webSocket = new WebSocket(this.wsURL + this.currentUser.id);
        this.$store.state.webSocket.onopen = this.webSocketOnOpen;
        this.$store.state.webSocket.onclose = this.webSocketOnClose;
        this.$store.state.webSocket.onerror = this.webSocketOnError;
        this.$store.state.webSocket.onmessage = this.webSocketOnMessage;
      }
    },
    webSocketOnOpen() {
    },
    webSocketOnClose(res) {
    },
    webSocketOnError() {
    },
    webSocketOnMessage(res) {
      let message = JSON.parse(res.data);
      if (message.type === 'privateMessage') {
        let privateMessage = message.privateMessage;
        try {
          this.$store.state.webSocketMethod.onReceivedPrivateMessage(privateMessage);
        } catch (e) {
        }
      } else if (message.type === 'groupMessage') {
        let groupMessage = message.groupMessage;
        try {
          this.$store.state.webSocketMethod.onReceivedGroupMessage(groupMessage);
        } catch (e) {
        }
      } else if (message.type === 'updateConversations') {
        try {
          this.$store.state.webSocketMethod.onConversationsUpdate();
        } catch (e) {
        }
      } else if (message.type === 'updatePrivateMessage') {
        this.$store.state.webSocketMethod.onConversationsUpdate();
        let updatePrivateMessage = message.privateMessage;
        try {
          this.$store.state.webSocketMethod.onPrivateMessageUpdate(updatePrivateMessage);
        } catch (e) {
        }
      } else if (message.type === 'updateGroupMessage') {
        this.$store.state.webSocketMethod.onConversationsUpdate();
        let updateGroupMessage = message.groupMessage;
        try {
          this.$store.state.webSocketMethod.onGroupMessageUpdate(updateGroupMessage);
        } catch (e) {
        }
      }
    },
    selectAvatar(index) {
      this.information.selectedAvatar = index;
      this.informationData.avatar = this.information.avatars[index];
    },
    saveInformation() {
      this.$http.put("/user/update", this.informationData).then((res) => {
        if (res.data.success) {
          this.$message({
            message: res.data.msg,
            type: 'success'
          });
          console.log(res.data.data)
          this.globalData.currentUser = res.data.data;
          this.currentUser = this.globalData.currentUser;
          localStorage.setItem("user_save", JSON.stringify(this.currentUser));
        } else {
          this.$message.error(res.data.msg);
        }
      })
      this.information.visible = false;
    }
  },
};
</script>

<style scoped>
.home-container {
  background: #FFFFFF;
  display: flex;
  position: relative;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.home-menu {
  width: 60px;
  background-color: #f8f8f8;
  border-right: 1px solid #eeeeee;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.home {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(../assets/images/background1.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}


.menu-header {
  margin: 20px auto;
}

.user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 10px;
  cursor: pointer;
}

.user-profile {
//display: none; display: block; color: #FFFFFF; position: absolute; top: 10px; left: 70px; width: 250px;
//height: 200px; background: #FFFFFF; z-index: 999; box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.1); border-radius: 8px; border: 1px solid #EBEEF5; overflow: hidden;
}

.user-profile-main {
  background-color: #FFF;
  color: #303133;
//border-radius: 8px;
}

.user-profile-header {
  padding: 18px 20px;
  border-bottom: 1px solid #EBEEF5;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 15px;
  font-weight: bold;
}

.user-profile-header img {
  width: 70px;
  height: 70px;
  transition: 0.2s;

}

.user-profile-header img:hover {
  transform: scale(1.2);
  transition: 0.2s;

}

.user-profile-header div {
  margin-top: 10px;
}

.user-profile-info {
  display: flex;
  padding: 10px 20px;
  font-size: 14px;
  color: #666666;
  line-height: 28px;
}

.user-profile-footer {
  display: flex;
  padding: 10px 20px 20px;
}

.user-profile-info-title {
  width: 70px;
}

.user-profile-info-text {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  flex-shrink: 2;
}

.menu-box {
  padding: 40px 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.menu-list {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.menu-item {
  color: #303133;
  cursor: pointer;
  width: 56px;
  height: 56px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
}

.menu-item:hover {
  background: #e7e7e7;
}

.exit {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
}

.exit:hover {
  background: #ffc1c1;
}

.menu-unread {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 18px;
  height: 18px;
  line-height: 18px;
  text-align: center;
  border-radius: 50%;
  background-color: #F56C6C;
  color: #ffffff;
}

.router-link-active i {
  color: #1E88E5 !important;
}

.iconfont {
  padding: 15px;
  font-size: 28px;
  color: #606266;
  cursor: pointer;
}

.iconfont:active {
  color: #1E88E5;
}

.hmyIconfont {
  padding: 15px;
  font-size: 28px;
  color: #606266;
  cursor: pointer;
}

.hmyIconfont:active {
  color: #1E88E5;
}

.home-main {
  padding: 0;
  flex: 1;
}

.information-box {
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgba(51, 51, 51, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.information-box-main {
  width: 300px;
  /*height: 200px;*/
  background: #ffffff;
  z-index: 100;
  border-radius: 10px;
  overflow: hidden;
  padding: 20px;
}

.information-box-title {
  font-size: 25px;
  margin-bottom: 20px;
}

.information-box-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 20px;
}

.avatar-select {
  display: flex;
  justify-content: center;
  align-items: center;

  margin-bottom: 20px;
  flex-wrap: wrap;
}

.avatar-select div {
  cursor: pointer;
  border-radius: 50%;
//overflow: hidden; transition: all 0.1s ease-in-out; flex: 0 0 calc(20%);

}

.avatar-select img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  outline: 2px solid transparent;
}

.avatar-select .active img {
  border-radius: 50%;
  /*border: 2px solid #2979FF;*/
  outline-color: #2979FF;
  z-index: 1000;
}

.avatar-select .small {
  /* 当头像未被选中时，缩小它的尺寸 */
  transform: scale(0.8);
}

.avatar-select .large {
  /* 当头像被选中时，放大它的尺寸 */
  transform: scale(1.1);
}

.el-form-item {
  margin-bottom: 0;
}

@media screen and (min-width: 850px) and (min-height: 650px) {
  .home-container {
    width: 850px;
    height: 650px;
    border-radius: 10px;
  }
}

@media screen and (max-width: 850px) {
  .home-container {
    width: 100%;
    height: 100%;
    border-radius: 0px;
  }
}

@media screen and (max-height: 650px) {
  .home-container {
    width: 100%;
    height: 100%;
    border-radius: 0px;
  }
}

@media screen and (max-width: 700px) {
  .home-main {
    height: calc(100% - 60px)
  }

  .home-container {
    flex-direction: column-reverse;
  }

  .home-menu {
    flex-direction: row;
    width: 100%;
  }

  .menu-box {
    flex-direction: row;
    padding: 0;
    align-items: stretch;
    justify-content: center;
  }

  .menu-list {
    flex-direction: row;
  }

  .menu-header {
    margin: 0;
    position: absolute;
    left: 10px;
  }

  .user-profile {
    top: -290px;
    left: 0;
  }

  .menu-item-moments{
    //display: none;
  }
}
</style>
